html {
  background-color: #ffb5a5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom Text Font Family */

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "CornerOne Bold";
}

.text-primary {
  font-family: "CornerOne Regular";
}

/* Custom Text Colors */

.text-custom-light-green {
  color: #91c4ad;
}

.text-custom-rose {
  color: #ff9693;
}

.text-custom-green {
  color: #73a48e;
}

.text-custom-light-orange {
  color: #ffb5a5;
}

.text-custom-beige {
  color: #fae3d4;
}

/* Custom Background Colors */

.bg-custom-light-green {
  background-color: #91c4ad;
}

.bg-custom-rose {
  background-color: #ff9693;
}

.bg-custom-green {
  background-color: #73a48e;
}

.bg-custom-light-orange {
  background-color: #ffb5a5;
}

.bg-custom-beige {
  background-color: #fae3d4;
}

.border-custom-rose {
  border-color: #ff9693;
}

.border-custom-light-orange {
  border-color: #ffb5a5;
}

.border-custom-green {
  border-color: #73a48e;
}

.dialog-overlay {
  display: none;
}

/* Carousel Styles */
.carousel-container {
  margin: 20px auto;
}

.carousel {
  position: relative;
  width: 80%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 350px; /* Adjusted to fit within the section */
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.carousel-control.prev {
  left: 10px;
}

.carousel-control.next {
  right: 10px;
}
